import { EAuthCode } from '~/enums/EAuthCode';
import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.inviteBasicSetting,
    component: () => import('../../pages/inviteManagement/inviteBasicSetting'),
    meta: { title: '基本设置'
      , code:EAuthCode.INVITE_BASIC_SETTING
    }
  },
  {
    path: routeNames.giftList,
    component: () => import('../../pages/inviteManagement/giftList'),
    meta: { title: '礼品列表'
      , code:EAuthCode.GIFT_LIST
    }
  },
  {
    path: routeNames.packageList,
    component: () => import('../../pages/inviteManagement/packageList'),
    meta: { title: '套餐列表'
      , code:EAuthCode.PACKAGE_LIST
    }
  },
  {
    path: routeNames.inviteRecord,
    component: () => import('../../pages/inviteManagement/inviteRecord'),
    meta: { title: '邀请记录'
      , code:EAuthCode.INVITE_RECORD
    }
  },
  {
    path: routeNames.exchangeRecord,
    component: () => import('../../pages/inviteManagement/exchangeRecord'),
    meta: { title: '兑换记录'
     , code:EAuthCode.EXCHANGE_RECORD
    }
  },
  {
    path: routeNames.issueRecord,
    component: () => import('../../pages/inviteManagement/issueRecord'),
    meta: { title: '发放记录'
      , code:EAuthCode.ISSUE_RECORD
    }
  },
  {
    path: routeNames.giftForm,
    component: () => import('../../pages/inviteManagement/giftForm'),
    meta: { title: '新增礼品', hideInMenu: true}
  },
  {
    path: routeNames.selectGiftTable,
    component: () => import('../../pages/inviteManagement/selectGiftTable'),
    meta: { title: '选择礼品', hideInMenu: true}
  }
] as TRoutes[]