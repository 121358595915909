import React, { FC, memo } from 'react';
import { Button, Result } from 'antd';
import styles from './index.module.less';
import { Link } from 'react-router-dom';
import { routeNames } from '~/routes/const';

const Component: FC = () => {
  return (
    <Result
      status="403"
      //title="403"
      subTitle="权限已变更,请重新登录"
      // extra={<Button type="primary">Back Home</Button>}
    />
  )
}

Component.defaultProps = {};
Component.displayName = 'MMPNOMatch';

const MMPNOMatch = memo(Component);
export default MMPNOMatch;