import { EAuthCode } from '~/enums/EAuthCode';
import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.memberList,
    component: () => import('../../pages/memberList'),
    meta: { title: '会员列表'
      , code:EAuthCode.MEMBER_LIST
    }
  }
] as TRoutes[]