/**
 * 全局上下文。在这里你可以存放一些全局的变量数据
 */
import React from 'react';
import { TMenu } from '~/data-model/common';

type Key = keyof Omit<IGlobalContext, 'dispatch'>;
type Value = IGlobalContext[Key];

export interface IDispatch {
  (key: Key | Partial<Omit<IGlobalContext, 'dispatch'>>, value?: Value): void
}

export interface IGlobalContext {
  /** 用户名 */
  name: string,
  /** 应用名称 */
  applicationName: string;
  /** 菜单栏 */
  menus: TMenu[];
  /** 权限code */
  authCodes: string[];
  /** 数据修改 */
  dispatch: IDispatch
}

const globalContext = React.createContext<IGlobalContext>({
  name: '',
  applicationName: '',
  menus: [],
  authCodes: [],
  dispatch() { }
});
export default globalContext;
