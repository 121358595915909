import { IMenus, ISubMenu, TMenu } from "~/data-model/common";

/** 判断是否是菜单 */
export function isMenu(item: TMenu): item is IMenus {
  return !!(item as IMenus).path && !isSubMenu(item)
}

/** 判断是否是二级菜单 */
export function isSubMenu(data: TMenu): data is ISubMenu {
  return !!(data as ISubMenu).children
}

export function getMenuCrumbs(menus: TMenu[], pathname: string) {
  let crumbs: { title: string, path: string }[] = [];
  let flag = true;
  menus.forEach(item => {
    if (!flag) {
      return;
    } else if (isSubMenu(item)) {
      const res = getMenuCrumbs(item.children || [], pathname)
      if (res.length) {
        flag = false;
        crumbs.push({ title: item.title, path: '' })
        crumbs = crumbs.concat(res)
      }
    } else if (isMenu(item)) {
      const { path, title } = item
      if (path === pathname) {
        crumbs.push({ title, path })
        flag = false;
      }
    }
  })

  return crumbs;
}

function flatMenu(menus: TMenu[], parentKeys: string[] = []) {
  return menus.reduce((result, item) => {
    if (isSubMenu(item)) {
      const parentKey = parentKeys.join('') + item.title
      const res = flatMenu(item.children || [], parentKeys.concat(parentKey))
      result = result.concat(res)
    } else if (isMenu(item)) {
      result.push({ ...item, parentKeys })
    }
    return result;
  }, [] as (IMenus & { parentKeys: string[] })[])

}

export function getMenuSelectedAndOpenKey(menus: TMenu[], pathname: string) {
  const fMenus = flatMenu(menus);
  const menu = fMenus.find(item => (pathname + '/').indexOf(item.path + '/') !== -1);
  return {
    selectedKeys: menu ? [menu.path] : [],
    openKeys: menu ? menu.parentKeys : []
  }
}