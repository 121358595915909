import { FC, memo, ReactNode } from 'react';
import styles from './index.module.less';

export interface HeaderBarProps {
  crumbs?: string[]
  children?: ReactNode;
}

const Component: FC<HeaderBarProps> = (props) => {
  const { crumbs = [] } = props
  return (
    <div className={styles.component}>
      {/* 传递了面包屑数据显示面包屑 */}
      {!!crumbs.length && (
        <div className={styles.head}>
          <div className={styles.head_line} />
          <span className={styles.head_crumbs}>{crumbs.join(' / ')}</span>
        </div>
      )}
      {props.children}
    </div>
  )
}

Component.defaultProps = {};
Component.displayName = 'HeaderBar';

const HeaderBar = memo(Component);
export default HeaderBar;