import { EAuthCode } from '~/enums/EAuthCode';
import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.todos,
    component: () => import('../../pages/todos'),
    meta: { title: '待办事项',code:EAuthCode.TODOS_LIST}
  }
] as TRoutes[]