import { Location, NextFn } from 'MMRouter'
import { skipAuth } from '~/config'
import { IGlobalContext } from '~/contexts/global.context'
import { api } from '~/request'
import { getMenus } from '.'
import { filterMenuByCode } from './const'
/**
 * 路由全局守卫
 *
 * @export
 * @param {Location<S>} to  即将要进入的目标 路由对象
 * @param {Location<any>} from 当前导航正要离开的路由对象
 * @param {NextFn} next 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
 *    next() 守卫通过.进行路由跳转
 *    next(false): 中断当前的导航。URL 地址会重置到 from 路由对应的地址。
 *    next('/') 或者 next({ pathname: '/' }): 跳转到一个不同的地址。当前的导航被中断，然后进行一个新的导航。
 *    你可以向 next 传递任意Location对象，且允许设置诸如 replace: true 选项用来处理是跳转还是重定向
 * @param {IGlobalContext} globalContext 全局context对象
 */
export function routerBeforeEach<S = unknown, FS = unknown>(to: Location<S>, from: Location<FS>, next: NextFn, globalContext: IGlobalContext) {
  const { name } = globalContext
  const { meta } = to

  // 鉴权
  if (!!name || !!meta.noLogin) {
    return next()
  } else if (!name && !meta.noLogin) {
    // next()
    // 获取用户权限
    return api['auth/query_GET']()
      .then(({ data }) => {
        if (data) {
          const { buttonCodes = [], menusCodes = [], username } = data
          const authCodes: string[] = ([] as string[]).concat(buttonCodes, menusCodes)
          globalContext.dispatch({
            authCodes,
            name: username,
            menus: filterMenuByCode(getMenus(), menusCodes, skipAuth)
          })
          next()
        }
      })
      .catch(() => { })
  }
}

/**
 * 全局后置钩子
 * 
 * 你也可以注册全局后置钩子，然而和守卫不同的是，这些钩子不会接受 next 函数也不会改变导航本身
 *
 * @export
 * @param {Location<S>} to  即将要进入的目标 路由对象
 * @param {Location<any>} from 当前导航正要离开的路由对象
 * @param {IGlobalContext} globalContext 全局context对象
 */
export function routerAfterEach<S = unknown, FS = unknown>(to: Location<S>, from: Location<FS>, globalContext: IGlobalContext) {
  // 设置浏览器标签标题
  document.title = to.meta.title || globalContext.applicationName;
  // console.log('执行了afterEach', to, from)
}