import styles from './index.module.less'
import { FC, memo, useState, useContext } from 'react'
import { Layout } from 'antd'
import SideMenus from '../sideMenus'
import globalContext from '~/contexts/global.context'
import ContentLayout from '../contentLayout'
import UserInfo from './userInfo'
import logo from '~/assets/images/muliLogoW.png'
import logoSmall from '~/assets/images/muliLogoSmall.png'

const { Header, Sider } = Layout

const Component: FC<{ children?: any }> = (props) => {
  const [collapsed, setcollapsed] = useState(false)
  const { applicationName } = useContext(globalContext)

  const onCollapse = (collapsed: boolean) => {
    setcollapsed(collapsed)
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div>
          {collapsed ? <img src={logoSmall} className={styles.logo_sm} /> : <img src={logo} className={styles.logo} />}
        </div>
        <SideMenus />
      </Sider>
      <Layout className="site-layout">
        <Header className={styles.header}>
          <div className={styles.header_left} />
          <div className={styles.header_right}>
            <UserInfo />
          </div>
        </Header>
        {/* <Content>
          
        </Content> */}
        <ContentLayout>{props.children}</ContentLayout>
        {/* <Footer style={{ textAlign: 'center' }}> Design ©2018 Created by Ant UED</Footer> */}
      </Layout>
    </Layout>
  )
}

Component.defaultProps = {}
Component.displayName = 'BasicLayout'

const BasicLayout = memo(Component)
export default BasicLayout
