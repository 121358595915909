import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.bannerManagement,
    component: () => import('../../pages/contentManagement/bannerManagement'),
    meta: { title: '首页banner管理'
      , code:EAuthCode.BANNER_MANAGEMENT 
    }
  },
  {
    path: routeNames.courtesyIntroduce,
    component: () => import('../../pages/contentManagement/courtesyIntroduce'),
    meta: { title: '高定礼遇介绍' 
      , code:EAuthCode.COURTESY_INTRODUCE
    }
  },
  {
    path: routeNames.vipRightesRulues,
    component: () => import('../../pages/contentManagement/vipRightesRulues'),
    meta: { title: '会员权益' 
      , code:EAuthCode.VIP_RIGHTS_RULES
    }
  },
  {
    path: routeNames.aboutMuli,
    component: () => import('../../pages/contentManagement/aboutMuli'),
    meta: { title: '关于木里' 
      , code:EAuthCode.ABOUT_MULI
    }
  },
  {
    path: routeNames.shareSetting,
    component: () => import('../../pages/contentManagement/shareSetting'),
    meta: { title: '分享设置' 
      , code:EAuthCode.SHARE_SETTING
    }
  },
  {
    path: routeNames.muliTv,
    component: () => import('../../pages/contentManagement/muliTv'),
    meta: { title: '木里TV' 
      , code:EAuthCode.MULI_TV
    }
  }
]  as TRoutes[]
